import "bootstrap";
// import "c3";
import "chart.js";

import "./index.scss";
// fontawesome
import { config, dom, library } from "@fortawesome/fontawesome-svg-core";
import { faSyringe, faChevronRight } from "@fortawesome/free-solid-svg-icons";

library.add(faSyringe, faChevronRight);
dom.i2svg();

var chartColors = {
    red: "rgb(255, 99, 132)",
    orange: "rgb(255, 159, 64)",
    yellow: "rgb(255, 205, 86)",
    green: "rgb(75, 192, 192)",
    blue: "rgb(54, 162, 235)",
    purple: "rgb(153, 102, 255)",
    grey: "rgb(201, 203, 207)",
};

const PREF_S = [
    "北海道",
    "青森",
    "岩手",
    "宮城",
    "秋田",
    "山形",
    "福島",
    "茨城",
    "栃木",
    "群馬",
    "埼玉",
    "千葉",
    "東京",
    "神奈川",
    "新潟",
    "富山",
    "石川",
    "福井",
    "山梨",
    "長野",
    "岐阜",
    "静岡",
    "愛知",
    "三重",
    "滋賀",
    "京都",
    "大阪",
    "兵庫",
    "奈良",
    "和歌山",
    "鳥取",
    "島根",
    "岡山",
    "広島",
    "山口",
    "徳島",
    "香川",
    "愛媛",
    "高知",
    "福岡",
    "佐賀",
    "長崎",
    "熊本",
    "大分",
    "宮崎",
    "鹿児島",
    "沖縄",
];

const makeJapanHTML = function () {
    let s = [];

    for (let j = 0; j < PREF_S.length; j++) {
        const pref = PREF_S[j];

        const n = j;

        s.push(
            "<div id=jp" +
            (n + 1) +
            ' class="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2"><div id="jpBg' +
            (n + 1) +
            '" class="card mb-4 "><div class="card-body"></div></div></div>'
        );
    }

    return s.join("");
};

// 円グラフ出力値計算
var scalingFactor = function (all, n, flag) {
    // 0の場合グラフが表示されないので、とりあえず擬似的に出力
    // return (flag) ? n : (all == 0) ? 1 : all - n;
    return flag ? n : all - n;
};

/**
 * 1/数万件[10]という表示
 * @param {*} prefFlag true: 都道府県, false 全国
 * @param {*} all 総件数
 * @param {*} n 副反応有り件数
 * @param {*} n2 協力医療機関数
 */
var perStrFactor = function (prefFlag, all, n, n2) {
    // // データ無し
    // if (all === 0) {
    //     return "&nbsp;";
    // }

    // 都道府県だけの出力ならtrue
    const PREF_ONLY_FLAG = false;
    // 協力医療機関数出力
    var flag = PREF_ONLY_FLAG ? prefFlag : true;
    var html = n
        + "<small> / "
        + String(all).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")

    // 協力医療機関数の出力
    if (flag) {
        html = html + " <span>["
            + String(n2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
            + "]</span>"
    }

    html = html + "</small>";

    return html;
};

// パーセント計算
var perFactor = function (all, k) {
    if (all === 0) {
        return 0;
    }
    var result01 = (k / all) * 100;
    var n = 2; // 小数点第n位まで残す
    var result02 = Math.floor(result01 * Math.pow(10, n)) / Math.pow(10, n);
    // console.log(result02); // 結果 0.57
    return result02;
};

//=================
// json
//=================
// TODO 開発モードならtrue
//const DEBUG_FLAG = location.href.indexOf('.jp') == -1;
const DEBUG_FLAG = false;

//====  JSON URL
var url = "";
const param = "/alldata.ashx";
if (DEBUG_FLAG) {
    // 開発用URL
    const debugUrl = "https://mumps.children.jp";
    url = debugUrl + param;
} else {
    url = param;
}

(function ($) {
    $.ajaxSetup({
        cache: false
    });

    //=================
    // 都道府県テーブル出力
    //=================
    $("#prefBox").append(makeJapanHTML);
    $.support.cors = true;

    $.getJSON(url, function (data) {
        Object.keys(data).forEach(function (j) {
            // 都道府県コードをkeyにする
            var key = data[j]["都道府県コード"];

            var config = {
                // pie
                type: "doughnut",
                data: {
                    datasets: [
                        {
                            data: [
                                scalingFactor(
                                    data[key]["総件数"],
                                    data[key]["副反応有り件数"],
                                    true
                                ),
                                scalingFactor(
                                    data[key]["総件数"],
                                    data[key]["副反応有り件数"],
                                    false
                                ),
                            ],
                            backgroundColor: [chartColors.red, chartColors.grey],
                        },
                    ],
                    labels: ["副反応有り", "副反応無し"],
                },
                options: {
                    maintainAspectRatio: true,
                    responsive: true,
                    legend: {
                        position: "top",
                        display: key === 0,
                    },
                    title: {
                        position: key === 0 ? "bottom" : "top",
                        display: true,
                        text: key === 0 ? "全国" : PREF_S[key - 1],
                    },
                    animation: {
                        animateScale: true,
                        animateRotate: true,
                    },
                },
            };

            if (key === 0) {
                // 全国の数値
                $("#titleCount").html(
                    perStrFactor(false, data[key]["総件数"], data[key]["副反応有り件数"], data[key]["協力医療機関数"])
                );

                // ==== 全国の合計値出力
                $("#boxHead").append('<canvas id="contentsHead"></canvas>');
                var ctxHead = document.getElementById("contentsHead").getContext("2d");
                ctxHead.canvas.height = 250;
                new Chart(ctxHead, config);
            } else {
                var jpBgBox = $("#jpBg" + key + ' .card-body');
                if (jpBgBox.length > 0) {
                    if (data[key]["副反応有り件数"] !== 0) {
                        var pValue = perFactor(
                            data[key]["総件数"],
                            data[key]["副反応有り件数"],
                            data[key]["協力医療機関数"]
                        );

                        // 10％以上色付き
                        if (pValue > 40) {
                            jpBgBox
                                .addClass("card-alpha2");
                        } else if (pValue > 10) {
                            jpBgBox
                                .addClass("card-alpha1");
                        }
                    }

                    jpBgBox.append(
                        "<div class='text-center prefName h6'></div>"
                    );
                    jpBgBox
                        .children("div.prefName")
                        .html(PREF_S[key - 1]);
                    // データ表示
                    jpBgBox.append(
                        "<div class='text-center perBox h4'></div>"
                    );
                    jpBgBox
                        .children("div.perBox")
                        .html(
                            perStrFactor(true, data[key]["総件数"], data[key]["副反応有り件数"], data[key]["協力医療機関数"])
                        );
                }
            }
        });
    });
})(jQuery);